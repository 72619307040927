import React, { useCallback, useEffect, useState } from 'react';

import iconSMS from '../../assets/icons8-sms-96.png';
import '../../index.css';
import ChatBody from '../ChatBody';
import ChatFooter from '../ChatFooter';
import ChatHeader from '../ChatHeader';
import Sidebar from '../Sidebar';
import * as C from './styles';

import './index.css';

import { Socket } from "socket.io-client";
import chatService from "../../service/chatService";
import userService from "../../service/userService";

import { ChatContext, ModalNotificationContext, socket } from './chatContext';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchChats, fetchMessage, setCompanys, setDepartments, socketChatEnd, socketInitAttendance, socketInitAttendanceInDepartmentUpdate, socketInitTransferAgent, socketInitTransferAttendance, socketReceivedMessage } from '../../store/chat-actions';

import Modal from 'react-modal';

import { FileUpload, IMessage, MessageReply } from '../../interface/IModels';
import { CompanyItem } from '../../models/redux-models';
import departmentService from '../../service/departmentService';
import { EMessage } from '../../util/enums';

import toast, { Toaster } from 'react-hot-toast';

export type IMessages = IMessage[];

export type IMessageSocket = {
  phone: string,
  message: IMessage,
  participantName?: string,
}
export interface IMessagesQueue {
  messages: IMessage[],
  files: FileUpload[],
  isProcessing: boolean,
}

Modal.setAppElement('#root');

const Chat: React.FC = (props) => {

  useEffect(() => {
    // Verificar o status da permissão

    if (Notification.permission === 'default') {

      // Solicitar permissão se ainda não estiver definida

      Notification.requestPermission();

    }

  }, []);


  const theme = localStorage.getItem('theme');

  const [inputField, setInputField] = useState<string>("");
  const [inputFiles, setInputFiles] = useState<FileUpload[]>([]);
  const [messageReply, setMessageReply] = useState<MessageReply>({id:"", content:null});
  const [currentPhone, setCurrentPhone] = useState<string>('');
  const [socketConnection] = useState<Socket>(socket);
  const [modalNotification, setModalNotification] = useState<boolean>(true);

  const [message, setMessage] = useState<IMessageSocket>();

  const dispatch = useAppDispatch();

  const chat = useAppSelector(state => state.chat.chat);
  const companys = useAppSelector(state => state.chat.companysSelect);
  const chatsInAttendance = useAppSelector(state => state.chat.chatsInAttendance);

  const reloadChats = useCallback(() => {
    dispatch(fetchChats());
    //dispatch(resetChat());
  }, [dispatch]);

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '400px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--background)',
      border: '1px solid var(--background)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
  };

  const handleNotification = () => {
    localStorage.setItem("soundNotification", "true");
    setModalNotification(false);
  }
  const showNotification = (messageData: any) => {
    let notificationPrefs = localStorage.getItem("visualNotification");
    if (notificationPrefs === "true") {

      if (Notification.permission === 'default' || Notification.permission == 'denied') {
        Notification.requestPermission();
      }

      const options = {
        body: messageData.message.message,
        title: messageData.message.participant_name,
        icon: iconSMS,
      };
      const notification = new Notification(options.title, options);
    }
  };

  function playAudio() {
    const soundNotify = localStorage.getItem("soundNotification");
    
    const savedVolume = localStorage.getItem('notificationVolume');
    const volumeNotify = savedVolume ? parseInt(savedVolume, 10) : 50;

    if (soundNotify === "true") {
      const audio = new Audio(process.env.PUBLIC_URL + '/message.mp3');
      audio.volume = volumeNotify/100;
      audio.play();
    }
  }

  const handleSocketReceived = (phone: string, message: IMessage) => {
    if (chat && message && message.chat_id === chat.id) {
      dispatch(fetchMessage(message));
    } else {
      dispatch(socketReceivedMessage(message));
    }
  };

  useEffect(() => {
    setCurrentPhone(chat.phone);
    // fetchChats();
  }, [chat]);

  useEffect(() => {
    departmentService.getRelationUserDepartments().then(response => {
      if (typeof response == "boolean") return
      dispatch(setDepartments(response));
    });

    const companysToListener: CompanyItem[] = [];
    companys.forEach(comp => {
      let company: CompanyItem = {
        id: Number(comp.id),
        name: comp.name,
        url: comp.url,
      };

      companysToListener.push(company);

    });
    if (companysToListener.length > 0) initSocket(companysToListener)

  }, [companys])

  useEffect(() => {
    if (!message) return;

    handleSocketReceived(message.phone, message.message);
  }, [message]);

  let timeout: any | null = null;
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    if (socketConnection) {
      userService.getRelationUserCompany().then(response => {
        let companys: CompanyItem[] = [];

        response.forEach(e => {
          socketConnection.emit('create', 'room-' + e.id_company);

          let company: CompanyItem = {
            id: Number(e.id_company),
            name: e.name_company,
            url: e.url_company,
          };

          companys.push(company);

          // dispatch(addCompanyItem(company));
        });

        dispatch(setCompanys(companys))
      });

      socketConnection.on('connect', function () {
        userService.getRelationUserCompany().then(response => {
          let companys: CompanyItem[] = [];

          response.forEach(comp => {
            let company: CompanyItem = {
              id: Number(comp.id_company),
              name: comp.name_company,
              url: comp.url_company,
            };

            companys.push(company);

          });

          initSocket(companys)

        })

        setConnected(true);
        clearTimeout(timeout);
      });

      socketConnection.on("disconnect", (reason) => {
        setConnected(false);
        timeout = setInterval(() => {
          socketConnection.connect();
        }, 2000)
      })
    }

  }, [socketConnection]);

  const initSocket = (companys: CompanyItem[]) => {
    companys.forEach(comp => {
      socketConnection.emit('create', 'room-' + comp.id.toString());
    });
    socketConnection.removeListener("message-init")
    socketConnection.on("message-init", (res, data) => {
      playAudio();
      showNotification({
        message: {
          message: "Nova mensagem",
          participant_name: "Nova conversa"
        }
      });
      if (data.chat_id) {
        dispatch(socketInitAttendance(data.chat_id));
      }
    });
    socketConnection.removeListener("message-chatChangeDepartment")
    socketConnection.on("message-chatChangeDepartment", (res, data) => {
      if (data.chat_id) {
        dispatch(socketInitAttendanceInDepartmentUpdate(data.chat_id));
      }
    });
    socketConnection.removeListener("message-chatTransfer")
    socketConnection.on("message-chatTransfer", (res, data) => {
      if (data.chat_id) {
        dispatch(socketInitTransferAttendance(data.chat_id));
      }
    });
    socketConnection.removeListener("message-chatTransferAgent")
    socketConnection.on("message-chatTransferAgent", (res, data) => {
      if (data.chat_id) {
        dispatch(socketInitTransferAgent(data.chat_id));
      }
    });
    socketConnection.removeListener("message-chatEnd")
    socketConnection.on("message-chatEnd", (res, data) => {
      reloadChats(); //chatReloader
      dispatch(socketChatEnd(data.chat_id));
    })
  }


  const ReceivedMessageSocketConnection = () => {
    socketConnection.removeListener("message-received");

    socketConnection.on("message-received", (res, data) => {
      if (data.message.chat_id) {

        const chatInAttendace = chatsInAttendance.find(chat => chat.id == data.message.chat_id);
        if (!chatInAttendace) return;

        if(chatInAttendace?.agent_id && (chatInAttendace?.agent_id != (localStorage.getItem("id") ?? 0))) {
          setMessage(data);
          return;
        }

        if (data.sender && data.sender !== "agent") {
          playAudio();
          showNotification(data);
        }
        setMessage(data);
      }
    });
  }

  useEffect(() => {
    ReceivedMessageSocketConnection();
  }, [chatsInAttendance])

  useEffect(() => {
    if (inputField !== '') {
      const newMessageAgent: IMessage = {
        id: 0,
        message: inputField,
        chat_id: chat.id,
        message_time: new Date().getTime().toString(),
        sender: "agent",
        agent_id: Number(localStorage.getItem("id")),
        type_message: EMessage.TEXT,
        whatsapp_reference_message_id: messageReply.content?.whatsapp_message_id??""
      };

      dispatch({ type: "messageType/SET_MESSAGE", payload: { message: newMessageAgent, phone: currentPhone } });

      setInputField("");
      setMessageReply({id:"", content:null});
    }

  }, [inputField]);

  useEffect(() => {
    if (inputFiles.length === 0) return;

    const newMessageAgent: IMessage = {
      id: 0,
      message: localStorage.getItem("user") || "",
      chat_id: chat.id,
      message_time: new Date().getTime().toString(),
      sender: "agent",
      agent_id: Number(localStorage.getItem("id")),
      type_message: EMessage.TEXT,
      whatsapp_reference_message_id: messageReply.content?.whatsapp_message_id??""
    }

    inputFiles.map(async file => {
      newMessageAgent.type_message = getType(file.mimeType);

      if (getType(file.mimeType) === EMessage.DOCUMENT) newMessageAgent.message = formatText(file.name);

      toast.promise(chatService.createMessageAgent(newMessageAgent, chat.phone, file), {
        loading: `Enviando arquivo ${file.name}`,
        success: 'Arquivo enviado',
        error: 'Ocorreu um problema no envio',
      });

    });

    setInputFiles([]);
    setMessageReply({id:"", content:null});
    
  }, [inputFiles]);

  function formatText(content: string) {
    let nameUser = localStorage.getItem("user");
    let nameFormat = `*${nameUser}*\n`;
    let contentText = content;
    return nameFormat + contentText;
}

  function getType(mimeType: string) {
    let type = mimeType.split("/");
    switch (type[0]) {
      case 'text':
        return EMessage.DOCUMENT;
      case 'image':
        return EMessage.IMAGE;
      case 'audio':
        return EMessage.AUDIO;
      case 'video':
        return EMessage.VIDEO;
      default:
        return EMessage.DOCUMENT;
    }
  }

  const renderChat = () => {
    if (chat.id !== 0) {
      return (
        <ChatContext.Provider value={{ inputField, setInputField, inputFiles, setInputFiles, messageReply, setMessageReply }} >
          <ChatHeader chat={chat} />
          <ChatBody chat={chat} />
          {(chat.isHistory === undefined || chat.isHistory == false) ? <ChatFooter chat={chat} /> : ''}
        </ChatContext.Provider>
      );
    }
  };

  return (
    <C.ContainerRoot data-theme={theme ? theme : 'light'}>
      <C.ContainerApp>
        <ModalNotificationContext.Provider value={{ modalNotification, setModalNotification }}>
          <Sidebar />
        </ModalNotificationContext.Provider>

        <C.ContainerChat hide={chat.id == 0}>
          {renderChat()}
        </C.ContainerChat>
        <Modal
          isOpen={false}
          contentLabel="Modal"
          style={customStyles} >
          <C.ModalTitle>Permitir notificações</C.ModalTitle>
          <p>Permitir notificações sonoras</p>
          <C.ModalButtonRow>
            <C.ModalButton className='activeButtonModal' onClick={() => { handleNotification() }}>Sim</C.ModalButton>
            <C.ModalButton onClick={() => { setModalNotification(false); localStorage.setItem("soundNotification", "false") }}>Não</C.ModalButton>
          </C.ModalButtonRow>
        </Modal>
        <Toaster />
      </C.ContainerApp>
    </C.ContainerRoot>
  )
}

export default Chat;
