import React, { useState, useEffect } from 'react';
import { Slider, Typography, TextField, IconButton } from '@mui/material';
import { VolumeUp, PlayArrow } from '@mui/icons-material';
import { ModalTitle } from '../ChatHeader/styles';

const VolumeControl: React.FC = () => {
  const [volume, setVolume] = useState<number>(() => {
    const savedVolume = localStorage.getItem('notificationVolume');
    return savedVolume ? parseInt(savedVolume, 10) : 50;
  });

  useEffect(() => {
    localStorage.setItem('notificationVolume', volume.toString());
  }, [volume]);

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
    setVolume(newVolume);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseInt(event.target.value, 10);
    if (!isNaN(newVolume) && newVolume >= 0 && newVolume <= 100) {
      setVolume(newVolume);
    }
  };

  const playNotificationSound = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/message.mp3');
    audio.volume = volume / 100;
    audio.play();
  };

  return (
    <div style={{ padding: '2rem', background: "var(--backgroundModal)" }}>
      <ModalTitle>Configurar volume</ModalTitle>
      <Slider
        value={volume}
        onChange={handleVolumeChange}
        aria-labelledby="volume-slider"
        min={1}
        max={100}
      />
      <TextField
        value={volume}
        onChange={handleInputChange}
        inputProps={{ min: 1, max: 100, type: 'number' }}
        label="Volume"
        variant="outlined"
        size="small"
        style={{ marginTop: '1rem' }}
      />
      <IconButton onClick={playNotificationSound} style={{ marginTop: '1rem' }}>
        <VolumeUp />
      </IconButton>
    </div>
  );
};

export default VolumeControl;