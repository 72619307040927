import { FC } from 'react';
import { ChatItemModel } from '../../models/redux-models';
import { SidebarTabEnum, useFilter } from '../SidebarChat/sidebarChatContext';
import SidebarChatItem from '../SidebarChatItem';
import * as S from './styles';

const SidebarListChats: FC<{ allChatsHistory: ChatItemModel[], filteredChatsHistory: ChatItemModel[], newMessages: ChatItemModel[], filteredMessages: ChatItemModel[] }> = (props) => {
    const { sidebarTabSelected, setSidebarTabSelected } = useFilter();

    function listComponents() {
        if (props.allChatsHistory && props.allChatsHistory.length > 0) {

            if (props.filteredChatsHistory && props.filteredChatsHistory.length > 0) {
                return props.filteredChatsHistory?.map((element) => <SidebarChatItem isHistory={true} key={element.id} chat={element} />);
            }
            return props.allChatsHistory?.map((element) => <SidebarChatItem isHistory={true} key={element.id} chat={element} />);
        }

        if (props.newMessages.length > 0 || props.filteredMessages.length > 0) {
     
            let newMessagesFilteredCount, newMessagesCount = 0;

            if (props.filteredMessages.length > 0) {
                switch (sidebarTabSelected) {
                    case SidebarTabEnum.IN_ATTENDANCE:
                        
                        const inAttendance = props.filteredMessages?.filter(chat => chat.agent_id == localStorage.getItem("id"));
                        newMessagesFilteredCount = inAttendance.filter(chat => (chat.messages - chat.messages_read) > 0).length;
                        if(newMessagesFilteredCount > 0) {
                            document.title = `(${newMessagesFilteredCount}) Flow4Chat`;
                        } else {
                            document.title = "Flow4Chat";
                        }

                        return inAttendance.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                    case SidebarTabEnum.NO_ATTENDANT:
                        
                        const noAttendant = props.filteredMessages?.filter(chat => !chat.agent_id);
                        newMessagesFilteredCount = noAttendant.filter(chat => (chat.messages - chat.messages_read) > 0).length;
                        if(newMessagesFilteredCount > 0) {
                            document.title = `(${newMessagesFilteredCount}) Flow4Chat`;
                        } else {
                            document.title = "Flow4Chat";
                        }
                        
                        return noAttendant.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                    case SidebarTabEnum.IN_GENERAL_ATTENDANCE:
                        
                        const inGeneralAttendance = props.filteredMessages;
                        newMessagesFilteredCount = inGeneralAttendance.filter(chat => (chat.messages - chat.messages_read) > 0).length;
                        if(newMessagesFilteredCount > 0) {
                            document.title = `(${newMessagesFilteredCount}) Flow4Chat`;
                        } else {
                            document.title = "Flow4Chat";
                        }

                        return inGeneralAttendance?.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
                }
            }

            switch (sidebarTabSelected) {
                case SidebarTabEnum.IN_ATTENDANCE:

                    const inAttendance = props.newMessages.filter(chat => chat.agent_id == localStorage.getItem("id"));
                    newMessagesCount = inAttendance.filter(chat => (chat.messages - chat.messages_read) > 0).length;
                    if(newMessagesCount > 0) {
                        document.title = `(${newMessagesCount}) Flow4Chat`;
                    } else {
                        document.title = "Flow4Chat";
                    }
                    
                    return inAttendance.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);

                case SidebarTabEnum.NO_ATTENDANT:
                    
                    const noAttendant = props.newMessages.filter(chat => !chat.agent_id);
                    newMessagesCount = noAttendant.filter(chat => (chat.messages - chat.messages_read) > 0).length;
                    if(newMessagesCount > 0) {
                        document.title = `(${newMessagesCount}) Flow4Chat`;
                    } else {
                        document.title = "Flow4Chat";
                    }

                    return noAttendant.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);

                case SidebarTabEnum.IN_GENERAL_ATTENDANCE:
                    
                    const inGeneralAttendance = props.newMessages;
                    newMessagesCount = inGeneralAttendance.filter(chat => (chat.messages - chat.messages_read) > 0).length;
                    if(newMessagesCount > 0) {
                        document.title = `(${newMessagesCount}) Flow4Chat`;
                    } else {
                        document.title = "Flow4Chat";
                    }
                    return inGeneralAttendance.map((element) => <SidebarChatItem isHistory={false} key={element.id} chat={element} />);
            }
        }

        return <></>;
    }

    return <>
        <S.SidebarChatContainer>
            {listComponents()}
        </S.SidebarChatContainer>
    </>;
};

export default SidebarListChats;
